import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const nav_headers = [
        '< home />',
        '< projects />',
        '< experience />',
        '< resume />',
        '< contact />'
    ];

    return (
        <nav className="navbar">
            <div className="navbar_left">
                <a>
                    <span>&#x3c; home /&#x3e;</span>
                </a>
                <a>
                    <span>&#x3c; projects /&#x3e;</span>
                </a>
                <a>
                    <span>&#x3c; experience /&#x3e;</span>
                </a>
                <a>
                    <span>&#x3c; resume /&#x3e;</span>
                </a>
                <a>
                    <span>&#x3c; contact /&#x3e;</span>
                </a>
            </div>
        </nav>
    );
}

export default Navbar;