// Import components
import Navbar from './components/Navbar';
//import { ReactComponent as Mesh } from './assets/mesh.svg'
import Mesh from './components/Mesh';

// Import CSS style pages
import './components/Navbar.css';
import './App.css';

function App() {
  return (
    //<div style={{backgroundColor: '#313445', width: '100%', height: '100vh'}} >
    <>
      <Mesh className="background"/>
      <Navbar className="navigation"/>
    </>

    //</div>
  );
}

export default App;