import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

function Mesh() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas });

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x313445);

    const camera = new THREE.PerspectiveCamera(75, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
    camera.position.z = 5;

    const geometry = new THREE.BoxGeometry(5, 5, 5, 10, 10, 10);
    const material = new THREE.MeshBasicMaterial({ color: 0xFF6327, wireframe: true });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    renderer.setSize(canvas.clientWidth, canvas.clientHeight);

    function animate() {
      requestAnimationFrame(animate);
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;

      renderer.render(scene, camera);
    }

    animate();
  }, []);

  return <canvas 
  ref={canvasRef}
  style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: -9999
  }} />;
}

export default Mesh;